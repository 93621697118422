import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Checkbox,
    TextField,
    Box,
    Tab,
    Tabs,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
    Alert
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useRoom } from '../../contexts/RoomContext';
import { config } from '../../config';

// Демо-данные пользователей для примера
const DEMO_USERS = [
    { id: 1, name: 'Alice Johnson', email: 'alice.j@example.com', avatar: null },
    { id: 2, name: 'Bob Smith', email: 'bob.smith@example.com', avatar: null },
    { id: 3, name: 'Carol White', email: 'carol.w@example.com', avatar: null },
    { id: 4, name: 'David Brown', email: 'david.b@example.com', avatar: null },
    { id: 5, name: 'Eva Green', email: 'eva.g@example.com', avatar: null },
    { id: 6, name: 'Test Testing', email: 'barrikada.st.dev@gmail.com', avatar: null },
];

const InviteDialog = ({ open, onClose, roomId }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [emailInput, setEmailInput] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [sendError, setSendError] = useState(null);
    const [isInvitesSending, setIsInvitesSending] = useState(false);
    const [invitesError, setInvitesError] = useState(null);
    const { localUser } = useRoom();

    const handleToggleUser = (userId) => {
        setSelectedUsers(prev => 
            prev.includes(userId)
                ? prev.filter(id => id !== userId)
                : [...prev, userId]
        );
    };

    const handleSendInvites = async () => {
        if (selectedUsers.length === 0) return;

        const selectedUserData = DEMO_USERS.filter(user => 
            selectedUsers.includes(user.id)
        );

        // Показываем диалог подтверждения
        const confirmMessage = `Send invitations to:\n${selectedUserData.map(user => 
            `- ${user.name} (${user.email})`
        ).join('\n')}`;

        if (!window.confirm(confirmMessage)) return;

        setIsInvitesSending(true);
        setInvitesError(null);

        try {
            // Отправляем приглашения всем выбранным пользователям
            const results = await Promise.all(
                selectedUserData.map(user => 
                    fetch(`${config.api.baseUrl}${config.api.endpoints.invite}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: user.email,
                            roomId,
                            inviterName: localUser?.username || 'Someone'
                        }),
                    }).then(res => res.json())
                )
            );

            // Проверяем результаты
            const failedInvites = results.filter(result => !result.success);
            
            if (failedInvites.length > 0) {
                throw new Error(`Failed to send ${failedInvites.length} invitations`);
            }

            alert('All invitations sent successfully!');
            onClose();
            setSelectedUsers([]);
            setSearchQuery('');
        } catch (error) {
            console.error('Error sending invitations:', error);
            setInvitesError(error.message || 'Failed to send invitations. Please try again.');
        } finally {
            setIsInvitesSending(false);
        }
    };

    const handleCopyLink = async () => {
        const inviteLink = `${window.location.origin}/room/${roomId}`;
        try {
            await navigator.clipboard.writeText(inviteLink);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const handleSendEmail = async () => {
        if (!emailInput) return;
        
        setIsSending(true);
        setSendError(null);

        try {
            const response = await fetch(`${config.api.baseUrl}${config.api.endpoints.invite}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: emailInput,
                    roomId,
                    inviterName: localUser?.username || 'Someone'
                }),
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Failed to send invitation');
            }
            
            if (data.success) {
                setEmailInput('');
                alert('Invitation sent successfully!');
            } else {
                throw new Error(data.error || 'Failed to send invitation');
            }
        } catch (error) {
            console.error('Error sending invitation:', error);
            setSendError(error.message || 'Failed to send invitation. Please try again.');
        } finally {
            setIsSending(false);
        }
    };

    // Фильтрация пользователей по поисковому запросу
    const filteredUsers = DEMO_USERS.filter(user => 
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Invite People</DialogTitle>
            <DialogContent>
                <Tabs 
                    value={tabValue} 
                    onChange={(_, newValue) => setTabValue(newValue)}
                    sx={{ mb: 2 }}
                >
                    <Tab label="Users" />
                    <Tab label="Email" />
                    <Tab label="Link" />
                </Tabs>

                {tabValue === 0 && (
                    <>
                        <TextField
                            fullWidth
                            placeholder="Search users..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ mb: 2 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        
                        {invitesError && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {invitesError}
                            </Alert>
                        )}
                        
                        {filteredUsers.length === 0 ? (
                            <Box sx={{ 
                                textAlign: 'center', 
                                py: 3,
                                color: 'text.secondary'
                            }}>
                                <Typography variant="body2">
                                    No users found
                                </Typography>
                            </Box>
                        ) : (
                            <List sx={{ 
                                width: '100%',
                                maxHeight: '400px',
                                overflow: 'auto'
                            }}>
                                {filteredUsers.map((user) => (
                                    <ListItem
                                        key={user.id}
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                onChange={() => handleToggleUser(user.id)}
                                                checked={selectedUsers.includes(user.id)}
                                            />
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PersonIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={user.name}
                                            secondary={user.email}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </>
                )}

                {tabValue === 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            label="Email address"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            type="email"
                            error={!!sendError}
                            helperText={sendError}
                            disabled={isSending}
                        />
                        <Button
                            variant="contained"
                            onClick={handleSendEmail}
                            disabled={!emailInput || isSending}
                            startIcon={isSending ? <CircularProgress size={20} /> : <EmailIcon />}
                        >
                            {isSending ? 'Sending...' : 'Send Invitation'}
                        </Button>
                    </Box>
                )}

                {tabValue === 2 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                            fullWidth
                            value={`${window.location.origin}/room/${roomId}`}
                            InputProps={{ readOnly: true }}
                        />
                        <Tooltip title={copySuccess ? "Copied!" : "Copy link"}>
                            <IconButton onClick={handleCopyLink}>
                                <ContentCopyIcon color={copySuccess ? "success" : "inherit"} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onClose();
                    setSearchQuery('');
                    setSelectedUsers([]);
                    setInvitesError(null);
                }}>
                    Cancel
                </Button>
                {tabValue === 0 && (
                    <Button 
                        onClick={handleSendInvites}
                        disabled={selectedUsers.length === 0 || isInvitesSending}
                        variant="contained"
                        startIcon={isInvitesSending ? <CircularProgress size={20} /> : null}
                    >
                        {isInvitesSending ? 'Sending...' : 'Send Invites'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default InviteDialog; 