import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import socketService from '../services/socket';

const RoomContext = createContext();

export const RoomProvider = ({ children }) => {
    const [participants, setParticipants] = useState([]);
    const [localUser, setLocalUser] = useState(null);
    const [isRoomInitialized, setIsRoomInitialized] = useState(false);
    const [messages, setMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [directMessages, setDirectMessages] = useState({});
    const [unreadDirectMessages, setUnreadDirectMessages] = useState({});

    // Инициализация комнаты
    const initializeRoom = useCallback(() => {
        setParticipants([]);
        setLocalUser(null);
        setIsRoomInitialized(true);
    }, []);

    // Очистка при выходе из комнаты
    const cleanupRoom = useCallback(() => {
        setParticipants([]);
        setLocalUser(null);
        setIsRoomInitialized(false);
        setDirectMessages({});
        setMessages([]);
        setUnreadCount(0);
        setIsChatOpen(false);
        setUnreadDirectMessages({});
    }, []);

    // Добавление нового участника
    const addParticipant = useCallback((participant) => {
        if (!isRoomInitialized) return;
        setParticipants(prev => {
            if (!prev.find(p => p.id === participant.id)) {
                return [...prev, participant];
            }
            return prev;
        });
    }, [isRoomInitialized]);

    // Удаление участника
    const removeParticipant = useCallback((participantId) => {
        if (!isRoomInitialized) return;
        setParticipants(prev => prev.filter(p => p.id !== participantId));
    }, [isRoomInitialized]);

    // Обновление статуса участника
    const updateParticipantStatus = useCallback((participantId, updates) => {
        if (!isRoomInitialized) return;
        setParticipants(prev => 
            prev.map(p => 
                p.id === participantId 
                    ? { ...p, ...updates }
                    : p
            )
        );
    }, [isRoomInitialized]);

    // Установка локального пользователя
    const setLocalParticipant = useCallback((user) => {
        if (!isRoomInitialized) return;
        setLocalUser(user);
        addParticipant({ ...user, isLocal: true });
    }, [isRoomInitialized, addParticipant]);

    // Добавление сообщения в общий чат
    const addMessage = useCallback((message) => {
        setMessages(prev => {
            if (!prev.find(m => m.id === message.id)) {
                return [...prev, {
                    id: message.id || Date.now(),
                    timestamp: message.timestamp || new Date(),
                    ...message
                }];
            }
            return prev;
        });
        
        if (!isChatOpen) {
            setUnreadCount(prev => prev + 1);
        }
    }, [isChatOpen]);

    // Очистка непрочитанных сообщений
    const clearUnreadCount = useCallback(() => {
        setUnreadCount(0);
    }, []);

    // Переключение чата
    const toggleChat = useCallback(() => {
        setIsChatOpen(prev => !prev);
        if (!isChatOpen) {
            clearUnreadCount();
        }
    }, [isChatOpen, clearUnreadCount]);

    // Добавление личного сообщения
    const addDirectMessage = useCallback((message) => {
        const chatId = message.from === localUser?.id ? message.to : message.from;
        
        console.log('RoomContext adding direct message:', {
            message,
            chatId,
            localUserId: localUser?.id,
            currentMessages: directMessages[chatId]
        });
        
        setDirectMessages(prev => {
            const chatMessages = prev[chatId] || [];
            if (!chatMessages.find(m => m.id === message.id)) {
                const newMessages = {
                    ...prev,
                    [chatId]: [...chatMessages, message]
                };
                
                if (message.from !== localUser?.id) {
                    setUnreadDirectMessages(prev => ({
                        ...prev,
                        [chatId]: (prev[chatId] || 0) + 1
                    }));
                }
                
                return newMessages;
            }
            return prev;
        });
    }, [localUser]);

    // Получение сообщений для конкретного чата
    const getDirectMessages = useCallback((userId) => {
        const messages = directMessages[userId] || [];
        console.log('Getting direct messages:', {
            userId,
            messagesCount: messages.length,
            messages
        });
        return messages;
    }, [directMessages]);

    // Инициализация слушателя личных сообщений
    useEffect(() => {
        if (!localUser || !socketService.socket) return;

        console.log('Setting up direct message listener for user:', localUser.id);

        const socket = socketService.socket;

        const handleDirectMessage = (message) => {
            console.log('RoomContext received direct message:', {
                message,
                localUserId: localUser.id
            });
            
            // Проверяем, что сообщение относится к текущему пользователю
            if (message.to === localUser.id || message.from === localUser.id) {
                console.log('Message is relevant, adding to context');
                addDirectMessage(message);
            } else {
                console.log('Message is not relevant for current user');
            }
        };

        socket.on('direct-message', handleDirectMessage);

        return () => {
            console.log('Cleaning up direct message listener for user:', localUser.id);
            if (socket && socket.connected) {
                socket.off('direct-message', handleDirectMessage);
            }
        };
    }, [localUser, addDirectMessage]);

    // Очистка непрочитанных сообщений для конкретного чата
    const clearUnreadDirectMessages = useCallback((userId) => {
        setUnreadDirectMessages(prev => ({
            ...prev,
            [userId]: 0
        }));
    }, []);

    const value = {
        participants,
        localUser,
        isRoomInitialized,
        messages,
        unreadCount,
        isChatOpen,
        directMessages,
        unreadDirectMessages,
        initializeRoom,
        cleanupRoom,
        setLocalParticipant,
        addParticipant,
        removeParticipant,
        updateParticipantStatus,
        addMessage,
        clearUnreadCount,
        toggleChat,
        addDirectMessage,
        getDirectMessages,
        clearUnreadDirectMessages,
    };

    return (
        <RoomContext.Provider value={value}>
            {children}
        </RoomContext.Provider>
    );
};

export const useRoom = () => {
    const context = useContext(RoomContext);
    if (!context) {
        throw new Error('useRoom must be used within a RoomProvider');
    }
    return context;
};
