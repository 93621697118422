import { io } from 'socket.io-client';

const getSocketUrl = () => {
    if (window.location.hostname === 'v.moneyshop.ua') {
        return 'https://v.moneyshop.ua';
    }
    return window.location.hostname === 'localhost' 
        ? 'https://localhost:5000'
        : `https://${window.location.hostname}:5000`;
};

const SOCKET_SERVER = getSocketUrl();

class SocketService {
    constructor() {
        this.socket = null;
    }

    connect() {
        if (!this.socket) {
            const options = {
                transports: ['websocket'],
                secure: true,
                rejectUnauthorized: false,
                path: '/socket.io'
            };

            console.log('Connecting to socket server:', SOCKET_SERVER);
            this.socket = io(SOCKET_SERVER, options);
            
            this.setupSocketListeners();
        }
        return this.socket;
    }

    setupSocketListeners() {
        this.socket.on('connect', () => {
            console.log('Connected to socket server with ID:', this.socket.id);
        });

        this.socket.on('disconnect', () => {
            console.log('Disconnected from socket server');
        });

        this.socket.on('error', (error) => {
            console.error('Socket error:', error);
        });
    }

    joinRoom(roomId, userData) {
        if (this.socket) {
            this.socket.emit('join-room', roomId, userData);
        }
    }

    sendSignal(userId, signal) {
        console.log('Sending signal:', { to: userId, type: signal.type });
        this.socket.emit('signal', { userId, signal });
    }

    onUserJoined(callback) {
        if (this.socket) {
            this.socket.on('user-joined', callback);
        }
    }

    onUserLeft(callback) {
        this.socket.on('user-left', callback);
    }

    onRoomUsers(callback) {
        if (this.socket) {
            this.socket.on('room-users', callback);
        }
    }

    onSignal(callback) {
        this.socket.on('signal', callback);
    }

    disconnect() {
        if (this.socket) {
            console.log('Disconnecting socket...');
            
            // Удаляем все слушатели событий
            this.socket.removeAllListeners();
            
            // Отключаем сокет
            this.socket.disconnect();
            this.socket = null;
            
            console.log('Socket disconnected');
        }
    }

    onChatMessage(callback) {
        if (this.socket) {
            this.socket.on('chat-message', callback);
        }
    }

    sendChatMessage(message) {
        if (this.socket) {
            this.socket.emit('chat-message', message);
        }
    }

    sendDirectMessage(message) {
        if (!this.socket) {
            console.error('Socket not connected');
            return;
        }

        // Добавляем небольшую задержку перед отправкой
        setTimeout(() => {
            console.log('Sending direct message through socket:', message);
            this.socket.emit('direct-message', message);
        }, 0);
    }

    onDirectMessage(callback) {
        if (!this.socket) {
            console.error('Socket not connected');
            return;
        }

        // Удаляем предыдущий обработчик, если он есть
        this.socket.off('direct-message');
        
        // Устанавливаем новый обработчик
        this.socket.on('direct-message', (message) => {
            console.log('Socket received direct message:', message);
            callback(message);
        });
    }
}

const socketService = new SocketService();
export default socketService; 