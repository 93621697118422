import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Snackbar, Alert, Typography, Button } from '@mui/material';
import VideoGrid from './VideoGrid';
import Controls from './Controls';
import socketService from '../../services/socket';
import webRTCService from '../../services/webRTC';

import Chat from '../Chat/Chat';
import GPTChat from '../GPTChat/GPTChat';
import UsersPanel from '../UsersPanel/UsersPanel';
import { useRoom } from '../../contexts/RoomContext';
import TranscriptPanel from '../TranscriptPanel/TranscriptPanel';

const Room = () => {
    const { roomId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [streams, setStreams] = useState([]);
    const [isMuted, setIsMuted] = useState(false);
    const [isVideoOff, setIsVideoOff] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [isRetrying] = useState(false);
    const [mediaError, setMediaError] = useState(null);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isGPTChatOpen, setIsGPTChatOpen] = useState(false);
    const [isUsersOpen, setIsUsersOpen] = useState(false);
    const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
    const [isTranscriptActive, setIsTranscriptActive] = useState(false);
    const [transcript, setTranscript] = useState([]);
    const recognitionRefs = useRef(new Map());

    const { 
        participants,
        setLocalParticipant,
        addParticipant,
        removeParticipant,
        updateParticipantStatus,
        initializeRoom,
        cleanupRoom,
        addMessage
    } = useRoom();

    const generateRandomUsername = () => {
        return `User${Math.random().toString(36).substring(2, 8)}`;
    };

    const [username] = useState(() => {
        return location.state?.username || generateRandomUsername();
    });

    const handleTrack = useCallback((userId, stream, username) => {
        console.log('Handling track for user:', userId, {
            audioTracks: stream.getAudioTracks().length,
            videoTracks: stream.getVideoTracks().length,
            username
        });

        setStreams(prevStreams => {
            const existingStreamIndex = prevStreams.findIndex(s => s.id === userId);
            
            const newStream = {
                id: userId,
                stream: stream,
                username: username || `User ${userId.slice(0, 4)}`,
                isLocal: userId === socketService.socket.id
            };

            if (existingStreamIndex !== -1) {
                const updatedStreams = [...prevStreams];
                updatedStreams[existingStreamIndex] = newStream;
                return updatedStreams;
            }

            return [...prevStreams, newStream];
        });
    }, []);

    const cleanup = useCallback(() => {
        console.log('Cleaning up resources...');
        
        // Останавливаем все треки лоального стрима
        if (webRTCService.localStream) {
            webRTCService.localStream.getTracks().forEach(track => {
                track.stop();
                console.log(`Stopped track: ${track.kind}`);
            });
            webRTCService.localStream = null;
        }

        // Закрываем все peer connections
        webRTCService.closeAllConnections();
        
        // Отключаем сокет
        socketService.disconnect();
        
        // Очщаем состояние стримов
        setStreams([]);
    }, []);

    const handleLeaveRoom = useCallback(() => {
        cleanup();
        navigate('/');
    }, [cleanup, navigate]);

    useEffect(() => {
        let mounted = true;

        const handleRoomUsers = async (participants) => {
            if (!mounted) return;
            console.log('Room users:', participants);
            
            // Добавляем новых пользователей
            participants.forEach(participant => {
                if (participant.id !== socketService.socket.id) {
                    addParticipant({
                        id: participant.id,
                        username: participant.username || `User ${participant.id.slice(0, 4)}`,
                        isAudioEnabled: participant.isAudioEnabled ?? true,
                        isVideoEnabled: participant.isVideoEnabled ?? true,
                        isScreenSharing: participant.isScreenSharing ?? false
                    });
                }
            });
        };

        const initializeWebRTC = async () => {
            try {
                if (!mounted) return;
                
                initializeRoom();
                socketService.connect();
                
                // Ждем подключения сокета
                await new Promise((resolve) => {
                    if (socketService.socket.connected) {
                        resolve();
                    } else {
                        socketService.socket.once('connect', resolve);
                    }
                });

                // Получаем локальный стрим
                const localStream = await webRTCService.getLocalStream();
                if (!mounted) return;

                const socketId = socketService.socket.id;
                if (!socketId) {
                    throw new Error('Socket connection failed');
                }

                // Обновляем инициализацию локального стрима
                const localStreamData = {
                    id: socketId,
                    stream: localStream,
                    username: username,
                    isLocal: true
                };

                // Устанавливаем локальный стрим
                setStreams([localStreamData]);

                // Устанавливаем локального пользователя в контекст
                setLocalParticipant({
                    id: socketId,
                    username: username,
                    stream: localStream,
                    isAudioEnabled: true,
                    isVideoEnabled: true,
                    isScreenSharing: false
                });

                // Присоединяемся к комнате
                socketService.joinRoom(roomId, {
                    username: username,
                    isAudioEnabled: true,
                    isVideoEnabled: true,
                    isScreenSharing: false
                });

                // Устанавливаем колбэки WebRTC
                webRTCService.setCallbacks(
                    // onTrack callback
                    (userId, stream) => {
                        console.log('Track received from:', userId);
                        if (mounted && userId !== socketService.socket.id) {
                            const participant = participants.find(p => p.id === userId);
                            handleTrack(userId, stream, participant?.username);
                        }
                    },
                    // onIceCandidate callback
                    (userId, candidate) => {
                        if (mounted) {
                            console.log('Sending ICE candidate to:', userId);
                            socketService.sendSignal(userId, { 
                                type: 'candidate', 
                                candidate 
                            });
                        }
                    }
                );

                // Инициализ��руем обработчики событий комнаты
                initializeRoomConnection(localStream);

                // Устанавливаем обработчики
                socketService.onRoomUsers(handleRoomUsers);
            } catch (error) {
                console.error('Failed to initialize WebRTC:', error);
                if (mounted) {
                    setMediaError(error.message);
                }
            }
        };

        const initializeRoomConnection = (localStream) => {
            if (!localStream) {
                console.error('No local stream available');
                return;
            }

            console.log('Initializing room connection with local stream:', {
                audioTracks: localStream.getAudioTracks().length,
                videoTracks: localStream.getVideoTracks().length,
                tracks: localStream.getTracks().map(t => ({
                    kind: t.kind,
                    enabled: t.enabled,
                    readyState: t.readyState
                }))
            });

            // Обработка списка пользователей в комнате
            socketService.onRoomUsers((participants) => {
                console.log('Room users updated:', participants);
                
                participants.forEach(participant => {
                    if (participant.id !== socketService.socket.id) {
                        addParticipant({
                            id: participant.id,
                            username: participant.username || `User ${participant.id.slice(0, 4)}`,
                            isAudioEnabled: participant.isAudioEnabled ?? true,
                            isVideoEnabled: participant.isVideoEnabled ?? true,
                            isScreenSharing: participant.isScreenSharing ?? false
                        });
                    }
                });
            });

            // Обработка сигналов WebRTC
            socketService.onSignal(async ({ userId, signal }) => {
                console.log('Received signal:', { from: userId, type: signal.type });
                try {
                    switch (signal.type) {
                        case 'offer':
                            console.log('Processing offer from:', userId);
                            let peerConnection = webRTCService.peerConnections.get(userId);
                            if (!peerConnection) {
                                peerConnection = await webRTCService.createPeerConnection(userId);
                            }
                            const answer = await webRTCService.handleOffer(userId, signal.offer);
                            if (answer) {
                                console.log('Sending answer to:', userId);
                                socketService.sendSignal(userId, { 
                                    type: 'answer', 
                                    answer,
                                    username: username
                                });
                            }
                            break;

                        case 'answer':
                            console.log('Processing answer from:', userId);
                            await webRTCService.handleAnswer(userId, signal.answer);
                            break;

                        case 'candidate':
                            console.log('Processing ICE candidate from:', userId);
                            if (signal.candidate) {
                                await webRTCService.handleIceCandidate(userId, signal.candidate);
                            }
                            break;

                        default:
                            console.warn('Unknown signal type:', signal.type);
                    }
                } catch (error) {
                    console.error('Error handling signal:', error);
                    try {
                        console.log('Attempting to restart connection with:', userId);
                        const offer = await webRTCService.restartConnection(userId);
                        if (offer) {
                            socketService.sendSignal(userId, { 
                                type: 'offer', 
                                offer,
                                username: username
                            });
                        }
                    } catch (retryError) {
                        console.error('Failed to restart connection:', retryError);
                    }
                }
            });

            // Обработка нового пользователя
            socketService.onUserJoined(async (participant) => {
                console.log('New user joined:', participant);
                if (participant.id !== socketService.socket.id) {
                    try {
                        // Создаем peer connection для нового пользователя
                        const peerConnection = await webRTCService.createPeerConnection(participant.id);
                        
                        // Создаем и отправляем offer
                        const offer = await webRTCService.createOffer(participant.id);
                        if (offer) {
                            console.log('Sending offer to new user:', participant.id);
                            socketService.sendSignal(participant.id, { 
                                type: 'offer', 
                                offer,
                                username: username
                            });
                        }

                        // Добавляем участника
                        addParticipant({
                            id: participant.id,
                            username: participant.username || `User ${participant.id.slice(0, 4)}`,
                            isAudioEnabled: participant.isAudioEnabled ?? true,
                            isVideoEnabled: participant.isVideoEnabled ?? true,
                            isScreenSharing: participant.isScreenSharing ?? false
                        });
                    } catch (error) {
                        console.error('Error connecting to new user:', error);
                    }
                }
            });

            // Обработка выхода пользователя
            socketService.onUserLeft((userId) => {
                console.log('User left:', userId);
                removeParticipant(userId);
                setStreams(prevStreams => prevStreams.filter(s => s.id !== userId));
                webRTCService.closeConnection(userId);
            });

            // Обработка изменения медиа-статуса
            socketService.socket.on('mediaStatus', ({ userId, status }) => {
                console.log('Media status update:', userId, status);
                updateParticipantStatus(userId, status);
            });

            // Добавим периодическую проверку состояния соединений
            const checkConnectionsInterval = setInterval(() => {
                const connections = webRTCService.peerConnections;
                connections.forEach((connection, userId) => {
                    console.log(`Connection state for ${userId}:`, {
                        iceConnectionState: connection.iceConnectionState,
                        signalingState: connection.signalingState,
                        connectionState: connection.connectionState
                    });
                });
            }, 5000);

            // Обработка чат-сообщений
            socketService.socket.on('chat-message', (messageData) => {
                console.log('Received chat message:', messageData);
                addMessage(messageData);
            });

            return () => {
                clearInterval(checkConnectionsInterval);
            };
        };

        // Добавляем обрабочик beforeunload
        window.addEventListener('beforeunload', cleanup);
        
        // Инициализируем медиа
        initializeWebRTC().catch(error => {
            console.error('WebRTC initialization failed:', error);
            setMediaError(error.message);
        });

        // Очистка при рамонтировани компонента
        return () => {
            console.log('Component unmounting, cleaning up...');
            cleanup();
            window.removeEventListener('beforeunload', cleanup);
            mounted = false;
            cleanupRoom(); // Очищаем состояние комнаты при размонтироваии
        };
    }, [
        roomId,
        username,
        handleTrack,
        cleanup,
        initializeRoom,
        cleanupRoom,
        setLocalParticipant,
        addParticipant
    ]);

    const handleToggleAudio = useCallback(() => {
        if (webRTCService.localStream) {
            const audioTrack = webRTCService.localStream.getAudioTracks()[0];
            if (audioTrack) {
                audioTrack.enabled = !audioTrack.enabled;
                setIsMuted(!audioTrack.enabled);
                
                const status = {
                    isAudioEnabled: audioTrack.enabled
                };
                
                // Обновяем локальный статус
                updateParticipantStatus(socketService.socket.id, status);
                
                // Оповещаем других участников
                socketService.socket.emit('mediaStatus', {
                    userId: socketService.socket.id,
                    status
                });
            }
        }
    }, [updateParticipantStatus]);

    const handleToggleVideo = useCallback(() => {
        if (webRTCService.localStream) {
            const videoTrack = webRTCService.localStream.getVideoTracks()[0];
            if (videoTrack) {
                videoTrack.enabled = !videoTrack.enabled;
                setIsVideoOff(!videoTrack.enabled);
                
                const status = {
                    isVideoEnabled: videoTrack.enabled
                };
                
                // Обновляем локальный статус
                updateParticipantStatus(socketService.socket.id, status);
                
                // Оповещаем других участников
                socketService.socket.emit('mediaStatus', {
                    userId: socketService.socket.id,
                    status
                });
            }
        }
    }, [updateParticipantStatus]);

    const handleCopyRoomId = useCallback(() => {
        navigator.clipboard.writeText(roomId);
        setSnackbarSeverity('success');
        setSnackbarMessage('Room ID copied to clipboard');
    }, [roomId]);

    const handleToggleChat = useCallback(() => {
        setIsChatOpen(prev => !prev);
    }, []);

    const handleToggleGPTChat = useCallback(() => {
        setIsGPTChatOpen(prev => !prev);
        // Закрываем обычный чат при открытии GPT чта
        if (!isGPTChatOpen) {
            setIsChatOpen(false);
        }
    }, [isGPTChatOpen]);

    const handleToggleUsers = useCallback(() => {
        setIsUsersOpen(prev => !prev);
        if (!isUsersOpen) {
            setIsChatOpen(false);
            setIsGPTChatOpen(false);
        }
    }, [isUsersOpen]);

    const handleToggleTranscript = useCallback(() => {
        if (!isTranscriptActive) {
            setIsTranscriptActive(true);
            setIsTranscriptOpen(true);

            // Получаем все активные аудиопотоки
            const activeStreams = streams.filter(stream => 
                stream.stream.getAudioTracks().length > 0 && 
                stream.stream.getAudioTracks()[0].enabled
            );

            console.log('Starting transcript for streams:', activeStreams.map(s => ({
                id: s.id,
                username: s.username,
                hasAudio: s.stream.getAudioTracks().length > 0
            })));

            activeStreams.forEach(stream => {
                if (recognitionRefs.current.has(stream.id)) return;

                try {
                    // Создаем контекст для обработки аудио
                    const audioContext = new AudioContext();
                    const source = audioContext.createMediaStreamSource(stream.stream);
                    
                    // Создаем фильтры для шумоподавления
                    const lowpass = audioContext.createBiquadFilter();
                    lowpass.type = 'lowpass';
                    lowpass.frequency.value = 8000;
                    lowpass.Q.value = 1;

                    const highpass = audioContext.createBiquadFilter();
                    highpass.type = 'highpass';
                    highpass.frequency.value = 100;
                    highpass.Q.value = 1;

                    // Создаем узел для усиления сигнала
                    const gainNode = audioContext.createGain();
                    gainNode.gain.value = 1.5; // Усиливаем сигнал

                    // Подключаем фильтры
                    source.connect(highpass);
                    highpass.connect(lowpass);
                    lowpass.connect(gainNode);
                    gainNode.connect(audioContext.destination);

                    // Настраиваем распознавание речи
                    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
                    recognition.continuous = true;
                    recognition.interimResults = true;
                    recognition.lang = 'ru-RU';

                    // Увеличиваем чувствительность
                    recognition.maxAlternatives = 1;

                    let currentSpeech = '';
                    let restartTimeout;

                    recognition.onresult = (event) => {
                        const result = event.results[event.results.length - 1];
                        const text = result[0].transcript;
                        
                        if (result[0].confidence > 0.7) {
                            currentSpeech = text;

                            if (result.isFinal) {
                                // Находим актуальную информацию об участнике
                                const participant = participants.find(p => p.id === stream.id);
                                const username = participant?.username || stream.username || 'Unknown User';
                                
                                if (currentSpeech.trim()) {
                                    console.log('Adding transcript entry:', {
                                        username,
                                        text: currentSpeech.trim()
                                    });
                                    
                                    setTranscript(prev => [...prev, {
                                        username,
                                        text: currentSpeech.trim(),
                                        timestamp: new Date()
                                    }]);
                                }
                                currentSpeech = '';
                            }
                        }
                    };

                    recognition.onerror = (event) => {
                        console.error('Speech recognition error:', event.error);
                        // Перезапускаем распознавание при ошибке
                        if (event.error === 'no-speech' || event.error === 'network') {
                            recognition.stop();
                            restartTimeout = setTimeout(() => {
                                if (isTranscriptActive) {
                                    recognition.start();
                                }
                            }, 1000);
                        }
                    };

                    recognition.onend = () => {
                        // Автоматически перезапускаем распознавание, если оно все еще активно
                        if (isTranscriptActive) {
                            recognition.start();
                        }
                    };

                    recognition.start();
                    recognitionRefs.current.set(stream.id, {
                        recognition,
                        audioContext,
                        source,
                        filters: { lowpass, highpass, gainNode },
                        restartTimeout
                    });
                } catch (error) {
                    console.error('Error setting up speech recognition for user:', stream.username, error);
                }
            });
        } else {
            setIsTranscriptActive(false);
            
            // Останавливаем все распознаватели
            recognitionRefs.current.forEach(({ 
                recognition, 
                audioContext, 
                source, 
                filters, 
                restartTimeout 
            }) => {
                recognition.stop();
                clearTimeout(restartTimeout);
                source.disconnect();
                filters.lowpass.disconnect();
                filters.highpass.disconnect();
                filters.gainNode.disconnect();
                audioContext.close();
            });
            recognitionRefs.current.clear();
        }
    }, [isTranscriptActive, streams, participants]);

    // Обновляем cleanup эффект
    useEffect(() => {
        return () => {
            recognitionRefs.current.forEach(({ 
                recognition, 
                audioContext, 
                source, 
                filters, 
                restartTimeout 
            }) => {
                recognition.stop();
                clearTimeout(restartTimeout);
                source.disconnect();
                filters.lowpass.disconnect();
                filters.highpass.disconnect();
                filters.gainNode.disconnect();
                audioContext.close();
            });
            recognitionRefs.current.clear();
        };
    }, []);

    const streamsWithUsernames = streams.map(stream => {
        const participant = participants.find(p => p.id === stream.id);
        return {
            ...stream,
            username: participant?.username || 'Unknown User'
        };
    });

    return (
        <Box sx={{ 
            height: '100vh', 
            display: 'flex', 
            flexDirection: 'column',
            backgroundColor: 'background.default',
            transition: 'margin-right 0.3s ease-in-out',
            marginRight: (isChatOpen || isGPTChatOpen || isUsersOpen) ? '300px' : '0'
        }}>
            {mediaError ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: 2,
                    textAlign: 'center'
                }}>
                    <Typography variant="h6" color="error" gutterBottom>
                        {mediaError}
                    </Typography>
                    <Button 
                        variant="contained" 
                        onClick={() => window.location.reload()}
                        sx={{ mt: 2 }}
                    >
                        Try Again
                    </Button>
                </Box>
            ) : (
                <>
                    <Box sx={{ 
                        flex: 1,
                        minHeight: 0,
                        position: 'relative'
                    }}>
                        <VideoGrid 
                            streams={streamsWithUsernames} 
                            participants={participants}
                        />
                    </Box>
                    
                    <Box sx={{ 
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: (isChatOpen || isGPTChatOpen || isUsersOpen) ? '300px' : 0,
                        padding: '16px 24px',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        transition: 'right 0.3s ease-in-out',
                        zIndex: 1
                    }}>
                        <Controls
                            isMuted={isMuted}
                            isVideoOff={isVideoOff}
                            onToggleAudio={handleToggleAudio}
                            onToggleVideo={handleToggleVideo}
                            onLeaveRoom={handleLeaveRoom}
                            onCopyRoomId={handleCopyRoomId}
                            onToggleChat={handleToggleChat}
                            isChatOpen={isChatOpen}
                            disabled={isRetrying}
                            onToggleGPTChat={handleToggleGPTChat}
                            isGPTChatOpen={isGPTChatOpen}
                            onToggleUsers={handleToggleUsers}
                            isUsersOpen={isUsersOpen}
                            usersCount={participants.length}
                            webRTCService={webRTCService}
                            onToggleTranscript={handleToggleTranscript}
                            isTranscriptActive={isTranscriptActive}
                        />
                    </Box>
                </>
            )}

            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={6000}
                onClose={() => setSnackbarMessage('')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setSnackbarMessage('')} 
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Chat isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
            <GPTChat isOpen={isGPTChatOpen} onClose={() => setIsGPTChatOpen(false)} />
            <UsersPanel 
                isOpen={isUsersOpen} 
                onClose={() => setIsUsersOpen(false)}
            />
            <TranscriptPanel
                isOpen={isTranscriptOpen}
                onClose={() => setIsTranscriptOpen(false)}
                transcript={transcript}
                isRecording={isTranscriptActive}
            />
        </Box>
    );
};

export default Room; 