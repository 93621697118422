// Определяем базовый URL API в зависимости от окружения
const getApiUrl = () => {
    if (window.location.hostname === 'v.moneyshop.ua') {
        return 'https://v.moneyshop.ua';
    }
    return process.env.REACT_APP_API_URL || 'https://localhost:5000';
};

export const API_URL = getApiUrl();

// Можно добавить другие конфигурационные переменные
export const config = {
    api: {
        baseUrl: API_URL,
        endpoints: {
            invite: '/api/invite',
            // другие endpoints...
        }
    },
    app: {
        name: 'Video Chat',
        version: '1.0.0'
    }
}; 