import React, { useState } from 'react';
import { Box, TextField, IconButton, Typography, Paper } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { getOpenAIResponse } from '../../services/openai';

const GPTChat = ({ isOpen, onClose }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSend = async () => {
        if (!message.trim()) return;

        const userMessage = {
            id: Date.now(),
            text: message,
            sender: 'user',
            timestamp: new Date().toLocaleTimeString()
        };

        setMessages(prev => [...prev, userMessage]);
        setMessage('');
        setIsLoading(true);

        try {
            const response = await getOpenAIResponse(message);
            
            const gptResponse = {
                id: Date.now() + 1,
                text: response,
                sender: 'gpt',
                timestamp: new Date().toLocaleTimeString()
            };

            setMessages(prev => [...prev, gptResponse]);
        } catch (error) {
            console.error('Error getting GPT response:', error);
            // Add error message to chat
            const errorMessage = {
                id: Date.now() + 1,
                text: "Sorry, I encountered an error. Please try again.",
                sender: 'gpt',
                timestamp: new Date().toLocaleTimeString()
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                right: 0,
                top: 0,
                bottom: 0,
                width: '300px',
                backgroundColor: 'background.paper',
                transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
                transition: 'transform 0.3s ease-in-out',
                display: 'flex',
                flexDirection: 'column',
                borderLeft: '1px solid',
                borderColor: 'divider',
                zIndex: 1200,
                boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)'
            }}
        >
            <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AutoAwesomeIcon />
                    <Typography variant="h6">GPT Chat</Typography>
                </Box>
                <IconButton onClick={onClose} size="small" sx={{ color: 'inherit' }}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{ 
                flex: 1, 
                overflowY: 'auto',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}>
                {messages.map(msg => (
                    <Paper
                        key={msg.id}
                        sx={{
                            p: 1,
                            backgroundColor: msg.sender === 'user' ? 'primary.light' : 'background.default',
                            color: msg.sender === 'user' ? 'primary.contrastText' : 'text.primary',
                            maxWidth: '80%',
                            alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start'
                        }}
                    >
                        <Typography variant="caption" color={msg.sender === 'user' ? 'inherit' : 'text.secondary'}>
                            {msg.sender === 'user' ? 'You' : 'GPT'} • {msg.timestamp}
                        </Typography>
                        <Typography variant="body2">{msg.text}</Typography>
                    </Paper>
                ))}
                {isLoading && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                        <AutoAwesomeIcon fontSize="small" />
                        <Typography variant="body2">GPT is thinking...</Typography>
                    </Box>
                )}
            </Box>

            <Box sx={{ p: 2, borderTop: '1px solid', borderColor: 'divider' }}>
                <TextField
                    fullWidth
                    multiline
                    maxRows={4}
                    size="small"
                    placeholder="Ask GPT something..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    disabled={isLoading}
                    InputProps={{
                        endAdornment: (
                            <IconButton 
                                onClick={handleSend}
                                disabled={!message.trim() || isLoading}
                                size="small"
                                color="primary"
                            >
                                <SendIcon />
                            </IconButton>
                        )
                    }}
                />
            </Box>
        </Box>
    );
};

export default GPTChat; 