import React, { useState, useRef, useEffect } from 'react';
import { 
    Box, 
    Drawer, 
    Typography, 
    TextField, 
    IconButton,
    List,
    ListItem,
    ListItemText,
    Divider 
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { useRoom } from '../../contexts/RoomContext';
import socketService from '../../services/socket';

const Chat = () => {
    const { 
        messages, 
        addMessage, 
        isChatOpen, 
        toggleChat,
        localUser 
    } = useRoom();
    
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSend = () => {
        if (!newMessage.trim()) return;

        const messageData = {
            text: newMessage.trim(),
            senderId: localUser?.id,
            senderName: localUser?.username || 'You',
            type: 'chat',
            timestamp: new Date()
        };

        // Отправляем сообщение через сокет
        socketService.socket.emit('chat-message', messageData);
        
        // Добавляем сообщение локально
        addMessage(messageData);
        
        setNewMessage('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const formatTime = (date) => {
        return new Date(date).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
        });
    };

    return (
        <Drawer
            anchor="right"
            open={isChatOpen}
            onClose={toggleChat}
        >
            <Box sx={{ width: 300, height: '100%', display: 'flex', flexDirection: 'column' }}>
                {/* Заголовок */}
                <Box sx={{ 
                    p: 2, 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <Typography variant="h6">Chat</Typography>
                    <IconButton onClick={toggleChat} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Список сообщений */}
                <List sx={{ 
                    flex: 1, 
                    overflow: 'auto',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}>
                    {messages.map((message) => (
                        <ListItem
                            key={message.id}
                            sx={{
                                flexDirection: 'column',
                                alignItems: message.senderId === localUser?.id ? 'flex-end' : 'flex-start',
                                gap: 0.5
                            }}
                        >
                            <Typography
                                variant="caption"
                                color="text.secondary"
                            >
                                {message.senderId === localUser?.id ? 'You' : message.senderName}
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: message.senderId === localUser?.id ? 'primary.main' : 'grey.300',
                                    color: message.senderId === localUser?.id ? 'white' : 'black',
                                    borderRadius: 2,
                                    px: 2,
                                    py: 1,
                                    maxWidth: '80%'
                                }}
                            >
                                <Typography variant="body2">{message.text}</Typography>
                            </Box>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                            >
                                {formatTime(message.timestamp)}
                            </Typography>
                        </ListItem>
                    ))}
                    <div ref={messagesEndRef} />
                </List>

                <Divider />

                {/* Поле ввода */}
                <Box sx={{ p: 2, display: 'flex', gap: 1 }}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Type a message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        multiline
                        maxRows={4}
                    />
                    <IconButton 
                        color="primary" 
                        onClick={handleSend}
                        disabled={!newMessage.trim()}
                    >
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>
        </Drawer>
    );
};

export default Chat; 