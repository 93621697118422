import React, { useState, useEffect } from 'react';
import { Box, IconButton, Tooltip, Badge } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ChatIcon from '@mui/icons-material/Chat';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PeopleIcon from '@mui/icons-material/People';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useRoom } from '../../contexts/RoomContext';

const Controls = ({ 
    isMuted,
    isVideoOff,
    onToggleAudio,
    onToggleVideo,
    onLeaveRoom,
    onCopyRoomId,
    onToggleChat,
    isChatOpen,
    disabled,
    onToggleGPTChat,
    isGPTChatOpen,
    onToggleUsers,
    isUsersOpen,
    usersCount = 0,
    webRTCService,
    onToggleTranscript,
    isTranscriptActive,
}) => {
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [screenStream, setScreenStream] = useState(null);
    const { unreadCount, toggleChat } = useRoom();
    const [isMobile, setIsMobile] = useState(false);

    // Определяем, является ли устройство мобильным
    useEffect(() => {
        const checkMobile = () => {
            const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
            setIsMobile(isMobileDevice);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleScreenShare = async () => {
        try {
            if (!isScreenSharing) {
                const stream = await webRTCService.getDisplayMedia();
                setScreenStream(stream);
                setIsScreenSharing(true);

                // Слушаем событие окончания демонстрации экрана
                stream.getVideoTracks()[0].addEventListener('ended', () => {
                    setIsScreenSharing(false);
                    setScreenStream(null);
                });
            } else {
                // Останавливаем все треки демонстрации экрана
                screenStream.getTracks().forEach(track => track.stop());
                setIsScreenSharing(false);
                setScreenStream(null);
            }
        } catch (error) {
            console.error('Failed to toggle screen sharing:', error);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: 1, sm: 2 },
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: { xs: 1, sm: 2 },
                borderRadius: 2,
                backdropFilter: 'blur(10px)',
                maxWidth: '100%',
                mx: 'auto',
            }}
        >
            {/* Левая группа - основные контролы */}
            <Box sx={{ 
                display: 'flex', 
                gap: { xs: 1, sm: 2 },
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                <Tooltip title={isMuted ? "Unmute microphone" : "Mute microphone"}>
                    <IconButton
                        onClick={onToggleAudio}
                        color={isMuted ? 'error' : 'primary'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        {isMuted ? <MicOffIcon /> : <MicIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={isVideoOff ? "Turn on camera" : "Turn off camera"}>
                    <IconButton
                        onClick={onToggleVideo}
                        color={isVideoOff ? 'error' : 'primary'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        {isVideoOff ? <VideocamOffIcon /> : <VideocamIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={
                    isMobile 
                        ? "Screen sharing is not available on mobile devices" 
                        : (isScreenSharing ? "Stop sharing screen" : "Share screen")
                }>
                    <span>
                        <IconButton
                            onClick={handleScreenShare}
                            color={isScreenSharing ? 'primary' : 'default'}
                            disabled={disabled || isMobile}
                            sx={controlButtonStyle}
                        >
                            {isScreenSharing ? <StopScreenShareIcon /> : <ScreenShareIcon />}
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title="Copy room ID to clipboard">
                    <IconButton
                        onClick={onCopyRoomId}
                        color="primary"
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* Центральная группа - кнопка выхода */}
            <Tooltip title="Leave meeting">
                <IconButton
                    onClick={onLeaveRoom}
                    color="error"
                    disabled={disabled}
                    sx={controlButtonStyle}
                >
                    <CallEndIcon />
                </IconButton>
            </Tooltip>

            {/* Правая группа - дополнительные функции */}
            <Box sx={{ 
                display: 'flex', 
                gap: { xs: 1, sm: 2 },
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                <Tooltip title={isChatOpen ? "Close chat" : "Open chat"}>
                    <IconButton 
                        onClick={toggleChat}
                        color={isChatOpen ? 'primary' : 'default'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <Badge badgeContent={unreadCount} color="error">
                            <ChatIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>

                <Tooltip title={isGPTChatOpen ? "Close AI Assistant" : "Open AI Assistant"}>
                    <IconButton 
                        onClick={onToggleGPTChat}
                        color={isGPTChatOpen ? 'primary' : 'default'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <AutoAwesomeIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title={isUsersOpen ? "Close participants panel" : "Show participants"}>
                    <IconButton 
                        onClick={onToggleUsers}
                        color={isUsersOpen ? 'primary' : 'default'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <Box sx={{ position: 'relative' }}>
                            <PeopleIcon />
                            {usersCount > 0 && (
                                <Box sx={userCountBadgeStyle}>
                                    {usersCount}
                                </Box>
                            )}
                        </Box>
                    </IconButton>
                </Tooltip>

                <Tooltip title={
                    isMobile 
                        ? "Meeting transcript is not available on mobile devices"
                        : (isTranscriptActive ? "Stop recording transcript" : "Start recording transcript")
                }>
                    <span>
                        <IconButton
                            onClick={onToggleTranscript}
                            disabled={isMobile}
                            sx={{
                                ...controlButtonStyle,
                                backgroundColor: isTranscriptActive ? 'error.main' : 'default',
                                '&:hover': {
                                    backgroundColor: isTranscriptActive ? 'error.dark' : 'default',
                                },
                                opacity: isMobile ? 0.5 : 1
                            }}
                        >
                            {isTranscriptActive ? <StopCircleIcon /> : <RecordVoiceOverIcon />}
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );
};

const controlButtonStyle = {
    width: { xs: '40px', sm: '48px' },
    height: { xs: '40px', sm: '48px' },
    '& .MuiSvgIcon-root': {
        fontSize: { xs: '20px', sm: '24px' }
    }
};

const userCountBadgeStyle = {
    position: 'absolute',
    top: -8,
    right: -8,
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    borderRadius: '50%',
    width: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem'
};

export default Controls; 