import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

const VolumeIndicator = ({ stream, isActive }) => {
    const bars = 5; // Количество полосок индикатора
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const rafIdRef = useRef(null);
    const [volumes, setVolumes] = React.useState(new Array(bars).fill(0));

    useEffect(() => {
        if (!stream) return;

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;
        
        analyser.fftSize = 32;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        dataArrayRef.current = dataArray;

        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        const updateVolumes = () => {
            if (!analyserRef.current) return;
            
            analyserRef.current.getByteFrequencyData(dataArrayRef.current);
            const average = dataArrayRef.current.reduce((a, b) => a + b) / dataArrayRef.current.length;
            const normalizedVolume = Math.min(average / 128, 1);
            
            // Создаем эффект "падающих" полосок
            setVolumes(prev => prev.map((vol, i) => {
                const target = i / bars <= normalizedVolume ? 1 : 0;
                return vol + (target - vol) * 0.2;
            }));

            rafIdRef.current = requestAnimationFrame(updateVolumes);
        };

        updateVolumes();

        return () => {
            if (rafIdRef.current) {
                cancelAnimationFrame(rafIdRef.current);
            }
            if (source) {
                source.disconnect();
            }
            if (audioContext.state !== 'closed') {
                audioContext.close();
            }
        };
    }, [stream]);

    if (!isActive) return null;

    return (
        <Box sx={{ 
            display: 'flex',
            gap: 0.5,
            alignItems: 'flex-end',
            height: 16,
            ml: 1
        }}>
            {volumes.map((volume, i) => (
                <Box
                    key={i}
                    sx={{
                        width: 3,
                        height: `${Math.max(0.2, volume) * 100}%`,
                        backgroundColor: 'primary.main',
                        transition: 'height 0.1s ease-out',
                        borderRadius: 0.5,
                    }}
                />
            ))}
        </Box>
    );
};

export default VolumeIndicator; 