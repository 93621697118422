import React, { useState, useRef, useEffect } from 'react';
import {
    Drawer,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    TextField,
    Avatar,
    Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { useRoom } from '../../contexts/RoomContext';
import socketService from '../../services/socket';

const DirectChat = ({ isOpen, onClose, recipient }) => {
    const [newMessage, setNewMessage] = useState('');
    const { 
        localUser, 
        addDirectMessage, 
        getDirectMessages,
        clearUnreadDirectMessages 
    } = useRoom();
    const messagesEndRef = useRef(null);

    const messages = recipient ? getDirectMessages(recipient.id) : [];

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (isOpen && recipient) {
            clearUnreadDirectMessages(recipient.id);
        }
    }, [isOpen, recipient, clearUnreadDirectMessages]);

    const handleSend = () => {
        if (!newMessage.trim() || !recipient || !localUser) return;

        const message = {
            id: Date.now(),
            text: newMessage.trim(),
            from: localUser.id,
            to: recipient.id,
            timestamp: new Date().toISOString(),
            senderName: localUser.username
        };

        console.log('DirectChat sending message:', {
            message,
            localUserId: localUser.id,
            recipientId: recipient.id
        });
        
        addDirectMessage(message);
        socketService.sendDirectMessage(message);
        
        setNewMessage('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    if (!recipient) return null;

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            variant="persistent"
            sx={{
                width: 320,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 320,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Box sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column' 
            }}>
                {/* Header */}
                <Box sx={{ 
                    p: 2, 
                    display: 'flex', 
                    alignItems: 'center',
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <Avatar sx={{ mr: 2 }}>
                        {recipient.username[0].toUpperCase()}
                    </Avatar>
                    <Typography variant="subtitle1" sx={{ flex: 1 }}>
                        {recipient.username}
                    </Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Messages */}
                <List sx={{ 
                    flex: 1, 
                    overflow: 'auto',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}>
                    {messages.map((message) => (
                        <ListItem
                            key={message.id}
                            sx={{
                                flexDirection: 'column',
                                alignItems: message.from === localUser?.id ? 'flex-end' : 'flex-start',
                                gap: 0.5,
                                padding: 0
                            }}
                        >
                            <Box sx={{
                                maxWidth: '80%',
                                backgroundColor: message.from === localUser?.id ? 'primary.main' : 'action.hover',
                                borderRadius: 2,
                                padding: '8px 12px',
                            }}>
                                <Typography variant="body2">
                                    {message.text}
                                </Typography>
                            </Box>
                            <Typography 
                                variant="caption" 
                                color="text.secondary"
                                sx={{ px: 1 }}
                            >
                                {new Date(message.timestamp).toLocaleTimeString()}
                            </Typography>
                        </ListItem>
                    ))}
                    <div ref={messagesEndRef} />
                </List>

                {/* Input */}
                <Box sx={{ p: 2, display: 'flex', gap: 1 }}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Type a message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        multiline
                        maxRows={4}
                    />
                    <IconButton 
                        color="primary" 
                        onClick={handleSend}
                        disabled={!newMessage.trim()}
                    >
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>
        </Drawer>
    );
};

export default DirectChat; 