import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


// window.global = window;
// window.process = process;
// window.Buffer = [];

// Защита от внешних вмешательств
window.addEventListener('error', (event) => {
  // Игнорируем ошибки от расширений Chrome
  if (event.filename?.includes('chrome-extension://')) {
    event.preventDefault();
    return;
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

