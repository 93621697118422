import React from 'react';
import { 
    Box, 
    Typography, 
    IconButton, 
    Button,
    Drawer,
    Divider,
    Paper,    
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';

const TranscriptPanel = ({ 
    isOpen, 
    onClose, 
    transcript, 
    isRecording 
}) => {
    const handleDownload = () => {
        const text = transcript.map(entry => (
            `[${format(entry.timestamp, 'HH:mm:ss')}] ${entry.username}: ${entry.text}`
        )).join('\n');

        const blob = new Blob([text], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `meeting-transcript-${format(new Date(), 'yyyy-MM-dd-HH-mm')}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            variant="persistent"
            sx={{
                width: 320,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 320,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Box sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column' 
            }}>
                <Box sx={{ 
                    p: 2, 
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <Typography variant="h6">
                        Meeting Transcript
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        {isRecording && (
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: 'error.main',
                                    animation: 'pulse 1.5s infinite',
                                    '@keyframes pulse': {
                                        '0%': { opacity: 1 },
                                        '50%': { opacity: 0.4 },
                                        '100%': { opacity: 1 },
                                    }
                                }}
                            />
                        )}
                        <IconButton onClick={onClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ 
                    flex: 1, 
                    overflowY: 'auto',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}>
                    {transcript.length === 0 && !isRecording && (
                        <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ textAlign: 'center', py: 2 }}
                        >
                            No transcript available yet
                        </Typography>
                    )}
                    {transcript.length === 0 && isRecording && (
                        <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ textAlign: 'center', py: 2 }}
                        >
                            Recording in progress...
                        </Typography>
                    )}
                    {transcript.map((entry, index) => (
                        <Paper
                            key={index}
                            sx={{
                                p: 1,
                                backgroundColor: 'background.default'
                            }}
                        >
                            <Typography variant="caption" color="text.secondary">
                                {format(entry.timestamp, 'HH:mm:ss')} - {entry.username}
                            </Typography>
                            <Typography variant="body2">
                                {entry.text}
                            </Typography>
                        </Paper>
                    ))}
                </Box>

                <Divider />
                <Box sx={{ p: 2 }}>
                    <Button
                        fullWidth
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        onClick={handleDownload}
                        disabled={transcript.length === 0}
                    >
                        Download Transcript
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};

export default TranscriptPanel; 