import { createSafeContext } from '../utils/safeContext';

const configuration = {
    iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
    ],
    sdpSemantics: 'unified-plan',
    offerOptions: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
    }
};

class WebRTCService {
    constructor() {
        this.peerConnections = new Map();
        this.localStream = null;
        this.configuration = configuration;
        this.pendingCandidates = new Map();
        this.mediaOrder = null;
        this.onTrack = null;
        this.onIceCandidate = null;
    }

    setCallbacks(onTrack, onIceCandidate) {
        this.onTrack = onTrack;
        this.onIceCandidate = onIceCandidate;
    }

    async getLocalStream() {
        return createSafeContext(async () => {
            try {
                if (this.localStream) {
                    return this.localStream;
                }

                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                console.log('Device type:', isMobile ? 'mobile' : 'desktop');

                // Настройки для видео с учетом мобильных устройств
                const videoConstraints = {
                    width: { ideal: isMobile ? 640 : 1280, min: 320 },
                    height: { ideal: isMobile ? 480 : 720, min: 240 },
                    facingMode: 'user',
                    // Добавляем настройки для мобильных
                    frameRate: { ideal: 30, min: 15 }
                };

                try {
                    console.log('Requesting media with constraints:', {
                        video: videoConstraints,
                        audio: true
                    });

                    this.localStream = await navigator.mediaDevices.getUserMedia({
                        video: videoConstraints,
                        audio: true
                    });

                    // Проверяем полученные треки
                    const videoTrack = this.localStream.getVideoTracks()[0];
                    const audioTrack = this.localStream.getAudioTracks()[0];

                    console.log('Media stream obtained:', {
                        videoTrack: videoTrack ? {
                            enabled: videoTrack.enabled,
                            readyState: videoTrack.readyState,
                            settings: videoTrack.getSettings()
                        } : null,
                        audioTrack: audioTrack ? {
                            enabled: audioTrack.enabled,
                            readyState: audioTrack.readyState
                        } : null
                    });

                    return this.localStream;
                } catch (error) {
                    console.error('Error accessing media devices:', error);
                    throw error;
                }
            } catch (error) {
                console.error('Error in getLocalStream:', error);
                throw error;
            }
        });
    }

    async createPeerConnection(userId) {
        return createSafeContext(async () => {
            try {
                if (this.peerConnections.has(userId)) {
                    await this._cleanupConnection(userId);
                }

                console.log('Creating new peer connection for user:', userId);
                const peerConnection = new RTCPeerConnection(this.configuration);

                // Добавляем обработчик состояния соединения
                peerConnection.onconnectionstatechange = () => {
                    console.log(`Connection state changed for ${userId}:`, peerConnection.connectionState);
                    if (peerConnection.connectionState === 'failed') {
                        this.restartConnection(userId);
                    }
                };

                // Добавляем обработчик состояния ICE
                peerConnection.oniceconnectionstatechange = () => {
                    console.log(`ICE state changed for ${userId}:`, peerConnection.iceConnectionState);
                    if (peerConnection.iceConnectionState === 'failed') {
                        this.restartConnection(userId);
                    }
                };

                // Улучшенный обработчик треков
                peerConnection.ontrack = (event) => {
                    console.log('Track received in WebRTCService:', {
                        userId,
                        kind: event.track.kind,
                        streams: event.streams.length
                    });

                    if (this.onTrack && event.streams[0]) {
                        this.onTrack(userId, event.streams[0]);
                    }
                };

                // Обработчики ICE кандидатов
                peerConnection.onicecandidate = (event) => {
                    if (event.candidate && this.onIceCandidate) {
                        console.log('New ICE candidate:', event.candidate.candidate);
                        this.onIceCandidate(userId, event.candidate);
                    }
                };

                // Обработчик получения удаленного потока
                peerConnection.ontrack = (event) => {
                    console.log('Received track event:', {
                        kind: event.track.kind,
                        enabled: event.track.enabled,
                        readyState: event.track.readyState,
                        streams: event.streams.length
                    });

                    if (this.onTrack && event.streams[0]) {
                        const stream = event.streams[0];
                        
                        // Проверяем состояние треков
                        stream.getTracks().forEach(track => {
                            track.enabled = true; // Убедимся, что трек включен
                            
                            // Добавляем обработчики состояния
                            track.onended = () => console.log(`${track.kind} track ended`);
                            track.onmute = () => console.log(`${track.kind} track muted`);
                            track.onunmute = () => console.log(`${track.kind} track unmuted`);
                        });

                        // Вызываем колбэк с готовым стримом
                        this.onTrack(userId, stream);
                    }
                };

                // Добавляем локальные треки
                if (this.localStream) {
                    console.log('Adding local tracks to peer connection:', {
                        userId,
                        tracks: this.localStream.getTracks().length
                    });
                    
                    this.localStream.getTracks().forEach(track => {
                        peerConnection.addTrack(track, this.localStream);
                    });
                }

                this.peerConnections.set(userId, peerConnection);
                return peerConnection;
            } catch (error) {
                console.error('Error creating peer connection:', error);
                throw error;
            }
        });
    }

    async createOffer(userId) {
        const peerConnection = this.peerConnections.get(userId);
        if (!peerConnection) {
            throw new Error('No peer connection exists');
        }

        try {
            const offer = await peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
                iceRestart: true
            });

            await peerConnection.setLocalDescription(offer);
            return offer;
        } catch (error) {
            console.error('Error creating offer:', error);
            throw error;
        }
    }

    async handleAnswer(userId, answer) {
        try {
            const peerConnection = this.peerConnections.get(userId);
            if (!peerConnection) {
                throw new Error('No peer connection exists');
            }

            // Проверяем состояние перед установкой удаленного описания
            if (peerConnection.signalingState === 'stable') {
                console.warn('PeerConnection already in stable state, ignoring answer');
                return;
            }

            if (peerConnection.signalingState === 'have-local-offer') {
                await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
            } else {
                console.warn('Invalid signaling state for setting remote description:', peerConnection.signalingState);
            }
        } catch (error) {
            console.error('Error handling answer:', error);
            throw error;
        }
    }

    async handleIceCandidate(userId, candidate) {
        const peerConnection = this.peerConnections.get(userId);
        
        try {
            if (!peerConnection) {
                console.warn('No peer connection for ICE candidate:', userId);
                return;
            }

            if (peerConnection.remoteDescription) {
                await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
                console.log('ICE candidate added successfully');
            } else {
                console.warn('Skipping ICE candidate - no remote description');
            }
        } catch (error) {
            console.error('Error handling ICE candidate:', error);
            throw error;
        }
    }

    _cleanupConnection(userId) {
        const peerConnection = this.peerConnections.get(userId);
        if (peerConnection) {
            try {
                peerConnection.ontrack = null;
                peerConnection.onicecandidate = null;
                peerConnection.oniceconnectionstatechange = null;
                peerConnection.onsignalingstatechange = null;
                peerConnection.onicegatheringstatechange = null;
                peerConnection.onconnectionstatechange = null;
                peerConnection.close();
            } catch (error) {
                console.warn('Error during connection cleanup:', error);
            }
            this.peerConnections.delete(userId);
            this.pendingCandidates.delete(userId);
        }
    }

    async closeConnection(userId) {
        console.log(`Closing connection for user ${userId}`);
        await this._cleanupConnection(userId);
    }

    closeAllConnections() {
        console.log('Closing all connections and cleaning up...');
        
        // Закрываем все peer connections
        for (const [userId, connection] of this.peerConnections.entries()) {
            try {
                if (connection) {
                    connection.ontrack = null;
                    connection.onicecandidate = null;
                    connection.oniceconnectionstatechange = null;
                    connection.onsignalingstatechange = null;
                    connection.onicegatheringstatechange = null;
                    connection.onconnectionstatechange = null;
                    connection.close();
                    console.log(`Closed connection for user: ${userId}`);
                }
            } catch (error) {
                console.warn(`Error closing connection for user ${userId}:`, error);
            }
        }
        
        // Останавливаем все треи локального стрима
        if (this.localStream) {
            this.localStream.getTracks().forEach(track => {
                try {
                    track.stop();
                    console.log(`Stopped local track: ${track.kind}`);
                } catch (error) {
                    console.warn(`Error stopping track: ${track.kind}`, error);
                }
            });
            this.localStream = null;
        }

        // Очищаем все коллекции
        this.peerConnections.clear();
        this.pendingCandidates.clear();
        this.mediaOrder = null;

        console.log('All connections closed and cleaned up');
    }

    // Метод для перезапуска ICE при проблема с соединенем
    async restartConnection(userId) {
        const peerConnection = this.peerConnections.get(userId);
        if (peerConnection) {
            try {
                const offer = await peerConnection.createOffer({ iceRestart: true });
                await peerConnection.setLocalDescription(offer);
                return offer;
            } catch (error) {
                console.error('Error restarting connection:', error);
                throw error;
            }
        }
    }

    async handleOffer(userId, offer) {
        try {
            let peerConnection = this.peerConnections.get(userId);
            
            if (!peerConnection) {
                peerConnection = await this.createPeerConnection(userId);
            }

            // Проверяем состояние перед установкой удаленного описания
            if (peerConnection.signalingState !== 'stable') {
                console.warn('PeerConnection not in stable state, rolling back');
                await Promise.all([
                    peerConnection.setLocalDescription({type: "rollback"}),
                    peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
                ]);
            } else {
                await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            }

            // Создаем ответ
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);

            return answer;
        } catch (error) {
            console.error('Error handling offer:', error);
            throw error;
        }
    }

    // Добавляем метод для получения потока экрана
    async getDisplayMedia() {
        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true
            });
            
            // Сохраняем оригинальный видеопоток
            const originalVideoTrack = this.localStream.getVideoTracks()[0];
            
            // Заменяем видеопоток на поток экрана
            const screenTrack = screenStream.getVideoTracks()[0];
            
            // Обновляем все peer connections
            this.peerConnections.forEach((pc) => {
                const sender = pc.getSenders().find(s => s.track?.kind === 'video');
                if (sender) {
                    sender.replaceTrack(screenTrack);
                }
            });

            // Слушаем окончание демонстрации экрана
            screenTrack.addEventListener('ended', () => {
                this.peerConnections.forEach((pc) => {
                    const sender = pc.getSenders().find(s => s.track?.kind === 'video');
                    if (sender) {
                        sender.replaceTrack(originalVideoTrack);
                    }
                });
            });

            return screenStream;
        } catch (error) {
            console.error('Error accessing screen sharing:', error);
            throw error;
        }
    }
}

const webRTCService = new WebRTCService();
export default webRTCService; 