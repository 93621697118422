import React, { useState } from 'react';
import { Box } from '@mui/material';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const VideoGrid = ({ streams, participants = [] }) => {
    const [expandedStream, setExpandedStream] = useState(null);

    const handleExpand = (stream) => {
        setExpandedStream(expandedStream === stream ? null : stream);
    };

    if (!streams || streams.length === 0) {
        return (
            <Box sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'text.secondary'
            }}>
                Waiting for video...
            </Box>
        );
    }

    if (expandedStream) {
        return (
            <Box sx={{ 
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>
                {/* Развернутое видео с возможностью клика для возврата */}
                <Box 
                    sx={{ 
                        flex: 1,
                        position: 'relative',
                        marginBottom: '150px',
                        cursor: 'pointer', // Показываем, что элемент кликабельный
                        '&:hover::after': {
                            content: '"Click to exit fullscreen"',
                            position: 'absolute',
                            top: '16px',
                            right: '16px',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            padding: '8px 12px',
                            borderRadius: '4px',
                            fontSize: '14px',
                            opacity: 0.8
                        }
                    }}
                    onClick={() => handleExpand(null)} // Возврат к сетке по клику
                >
                    <VideoPlayer
                        stream={expandedStream.stream}
                        username={expandedStream.username}
                        muted={expandedStream.isLocal}
                        onExpand={() => handleExpand(null)}
                        participantsCount={participants.length}
                    />
                </Box>

                {/* Полоса с остальными видео */}
                <Box sx={{
                    position: 'absolute',
                    bottom: '80px',
                    left: 0,
                    right: 0,
                    height: '150px',
                    display: 'flex',
                    gap: 1,
                    p: 1,
                    background: 'transparent', // Полностью прозрачный фон
                    backdropFilter: 'blur(8px)',
                    overflowX: 'auto',
                    boxShadow: '0px -2px 15px rgba(0, 0, 0, 0.2)',
                    zIndex: 1,
                    // Стилизация скроллбара
                    '&::-webkit-scrollbar': {
                        height: '6px', // Уменьшаем высоту скроллбара
                        background: 'transparent' // Прозрачный фон скроллбара
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent', // Прозрачный фон трека
                        margin: '0 4px' // Отступы по краям
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(255, 255, 255, 0.3)',
                        borderRadius: '3px',
                        '&:hover': {
                            background: 'rgba(255, 255, 255, 0.5)'
                        }
                    },
                    // Добавляем градиент для визуального отделения
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '20px',
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), transparent)',
                        pointerEvents: 'none'
                    }
                }}>
                    {streams
                        .filter(s => s !== expandedStream)
                        .map((stream) => (
                            <Box
                                key={stream.id}
                                sx={{
                                    width: '200px',
                                    height: '100%',
                                    flexShrink: 0,
                                    opacity: 0.85,
                                    transition: 'all 0.2s ease',
                                    transform: 'scale(0.98)',
                                    '&:hover': {
                                        opacity: 1,
                                        transform: 'scale(1)'
                                    }
                                }}
                            >
                                <VideoPlayer
                                    stream={stream.stream}
                                    username={stream.username}
                                    muted={stream.isLocal}
                                    onExpand={() => handleExpand(stream)}
                                    participantsCount={participants.length}
                                />
                            </Box>
                        ))}
                </Box>
            </Box>
        );
    }

    // Определяем стиль сетки в зависимости от количества участников
    const getGridStyle = (count) => {
        switch (count) {
            case 1:
                return {
                    gridTemplateColumns: '1fr',
                    gridAutoRows: '1fr'
                };
            case 2:
                return {
                    gridTemplateColumns: '1fr 1fr',
                    gridAutoRows: '1fr'
                };
            case 3:
            case 4:
                return {
                    gridTemplateColumns: '1fr 1fr',
                    gridAutoRows: '1fr'
                };
            case 5:
            case 6:
                return {
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridAutoRows: '1fr'
                };
            default:
                return {
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                    gridAutoRows: '1fr'
                };
        }
    };

    const gridStyle = getGridStyle(streams.length);

    return (
        <Box sx={{
            height: 'calc(100% - 80px)', // Оставляем место для контролов
            display: 'grid',
            ...gridStyle,
            gap: 2,
            p: 2,
            overflow: 'hidden'
        }}>
            {streams.map((stream) => (
                <Box
                    key={stream.id}
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        aspectRatio: '16/9',
                        backgroundColor: 'black',
                        borderRadius: 1,
                        overflow: 'hidden'
                    }}
                >
                    <VideoPlayer
                        stream={stream.stream}
                        username={stream.username}
                        muted={stream.isLocal}
                        onExpand={() => handleExpand(stream)}
                        participantsCount={participants.length}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default VideoGrid; 