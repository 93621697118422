import React, { useState } from 'react';
import {
    Drawer,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Tooltip,
    Divider,
    Badge
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useRoom } from '../../contexts/RoomContext';
import { useParams } from 'react-router-dom';
import InviteDialog from './InviteDialog';
import ChatIcon from '@mui/icons-material/Chat';
import DirectChat from '../DirectChat/DirectChat';

const UsersPanel = ({ isOpen, onClose }) => {
    const { 
        participants, 
        unreadDirectMessages,
        clearUnreadDirectMessages 
    } = useRoom();
    const { roomId } = useParams();
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isDirectChatOpen, setIsDirectChatOpen] = useState(false);

    const handleOpenDirectChat = (participant) => {
        setSelectedUser(participant);
        setIsDirectChatOpen(true);
        clearUnreadDirectMessages(participant.id);
    };

    return (
        <>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={onClose}
                variant="persistent"
                sx={{
                    width: 300,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 300,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Box sx={{ 
                    p: 2, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between' 
                }}>
                    <Typography variant="h6">
                        Participants ({participants.length})
                    </Typography>
                    <Box>
                        <Tooltip title="Invite people">
                            <IconButton 
                                onClick={() => setIsInviteDialogOpen(true)}
                                sx={{ mr: 1 }}
                                color="primary"
                            >
                                <PersonAddIcon />
                            </IconButton>
                        </Tooltip>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <List>
                    {participants.map((participant) => (
                        <ListItem key={participant.id}>
                            <ListItemAvatar>
                                <Avatar>{participant.username[0].toUpperCase()}</Avatar>
                            </ListItemAvatar>
                            <ListItemText 
                                primary={participant.username}
                                secondary={participant.isLocal ? '(You)' : ''}
                            />
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                {!participant.isLocal && (
                                    <Tooltip title="Send direct message">
                                        <IconButton 
                                            size="small"
                                            onClick={() => handleOpenDirectChat(participant)}
                                        >
                                            <Badge 
                                                badgeContent={unreadDirectMessages[participant.id] || 0}
                                                color="error"
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        fontSize: '10px',
                                                        height: '16px',
                                                        minWidth: '16px'
                                                    }
                                                }}
                                            >
                                                <ChatIcon fontSize="small" />
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title={participant.isAudioEnabled ? 'Mic on' : 'Mic off'}>
                                    <Box component="span">
                                        {participant.isAudioEnabled ? (
                                            <MicIcon 
                                                color="success" 
                                                fontSize="small"
                                            />
                                        ) : (
                                            <MicOffIcon 
                                                color="error" 
                                                fontSize="small"
                                            />
                                        )}
                                    </Box>
                                </Tooltip>

                                <Tooltip title={participant.isVideoEnabled ? 'Camera on' : 'Camera off'}>
                                    <Box component="span">
                                        {participant.isVideoEnabled ? (
                                            <VideocamIcon 
                                                color="success" 
                                                fontSize="small"
                                            />
                                        ) : (
                                            <VideocamOffIcon 
                                                color="error" 
                                                fontSize="small"
                                            />
                                        )}
                                    </Box>
                                </Tooltip>

                                {participant.isScreenSharing && (
                                    <Tooltip title="Sharing screen">
                                        <ScreenShareIcon 
                                            color="primary" 
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                )}
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            <InviteDialog 
                open={isInviteDialogOpen}
                onClose={() => setIsInviteDialogOpen(false)}
                roomId={roomId}
            />

            <DirectChat
                isOpen={isDirectChatOpen}
                onClose={() => {
                    setIsDirectChatOpen(false);
                    setSelectedUser(null);
                }}
                recipient={selectedUser}
            />
        </>
    );
};

export default UsersPanel; 